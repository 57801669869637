import {
  Alert,
  AlertDescription,
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Icon,
  ListItem,
  Stack,
  Text,
  UnorderedList,
} from "@chakra-ui/react"
import React, { useState } from "react"
import { useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { FaCheckCircle } from "react-icons/fa"
import { useDebounce } from "react-use"
import { ZXCVBNResult } from "zxcvbn"
import PasswordInput from "~/components/account/PasswordInput"

interface Props {
  isLoading: boolean
}

const PasswordForm: React.FC<Props> = ({ isLoading }) => {
  const { t } = useTranslation()

  const rules = [
    {
      name: t("components.account.PasswordForm.rules.Length"),
      pattern: "?=.{8,}",
    },
    {
      name: t("components.account.PasswordForm.rules.Digit"),
      pattern: "?=.*[0-9]",
    },
    {
      name: t("components.account.PasswordForm.rules.Uppercase"),
      pattern: "?=.*[A-Z]",
    },
    {
      name: t("components.account.PasswordForm.rules.Lowercase"),
      pattern: "?=.*[a-z]",
    },
    {
      name: t("components.account.PasswordForm.rules.Special"),
      pattern: "?=.*[^A-Za-z0-9]",
    },
  ]

  const globalRule = new RegExp(rules.map(rule => `(${rule.pattern})`).join(""))

  const {
    watch,
    trigger,
    formState: { isSubmitted },
  } = useFormContext()

  const [ZXCVBNResult, setZXCVBNResult] = useState<ZXCVBNResult>()

  const password = watch("password")
  const confirmation = watch("confirmation")

  const isPwdRespectPolicy = globalRule.test(password)

  useDebounce(
    async () => {
      if (isSubmitted) {
        await trigger("password")
        await trigger("confirmation")
      }
    },
    10,
    [password, confirmation]
  )

  const getValidationRules = (fieldName: string) => {
    const mirror = fieldName === "password" ? confirmation : password

    return {
      required: t("components.account.PasswordForm.ValidationRules.Required"),
      minLength: {
        value: 8,
        message: t("components.account.PasswordForm.ValidationRules.MinLength"),
      },
      pattern: {
        value: globalRule,
        message: t("components.account.PasswordForm.ValidationRules.Generic"),
      },
      validate: (value: string) =>
        value === mirror ||
        t("components.account.PasswordForm.ValidationRules.Mismatch"),
    }
  }

  const onPwdStrengthCheck = (result: ZXCVBNResult) => {
    if (result) {
      setZXCVBNResult(result)
    }
  }

  return (
    <>
      <Stack
        flexDirection={{ base: "column-reverse", lg: "row" }}
        direction={{ base: "column", lg: "row" }}
        spacing={{ base: 0, lg: 30 }}
        alignItems="start"
      >
        <Stack spacing={5} width={{ base: "100%", lg: "50%" }}>
          <PasswordInput
            id="new-password"
            autoComplete="new-password"
            placeholder={t("components.account.PasswordForm.PasswordInput.New")}
            isFormLabelVisible={true}
            isSecurePasswordEnabled={true}
            isDisabled={isLoading}
            validation={getValidationRules("password")}
            onPwdStrengthCheck={onPwdStrengthCheck}
          />

          <PasswordInput
            id="confirm-password"
            name="confirmation"
            autoComplete="new-password"
            placeholder={t(
              "components.account.PasswordForm.PasswordInput.Confirm"
            )}
            isFormLabelVisible={true}
            isDisabled={isLoading}
            validation={getValidationRules("confirmation")}
          />

          <Flex justifyContent="flex-end" width="100%">
            <Button
              id="submit-password"
              colorScheme="primary"
              color="primary.text"
              type="submit"
              isLoading={isLoading}
              isDisabled={!isPwdRespectPolicy}
            >
              {t("components.account.PasswordForm.PasswordInput.Submit")}
            </Button>
          </Flex>
        </Stack>

        <Box width={{ base: "100%", lg: "50%" }}>
          <Box mb={30}>
            <Stack
              shadow={{ base: "none", lg: "base" }}
              fontSize={{ base: "sm", lg: "md" }}
              p={{ base: 0, lg: 30 }}
              borderRadius={6}
              spacing={4}
            >
              <Heading
                as="h3"
                fontSize="md"
                textAlign={{ base: "left", lg: "center" }}
                fontStyle="italic"
                mb={2}
              >
                {t("components.account.PasswordForm.RulesHeading")}
              </Heading>

              {rules.map((rule, index) => (
                <Rule name={rule.name} regex={rule.pattern} key={index} />
              ))}
            </Stack>
          </Box>
        </Box>
      </Stack>

      {password && ZXCVBNResult?.feedback?.warning && (
        <Warning warning={ZXCVBNResult?.feedback?.warning!} />
      )}
      {password && ZXCVBNResult?.feedback?.suggestions.length! > 0 && (
        <Suggestions suggestions={ZXCVBNResult?.feedback?.suggestions!} />
      )}
    </>
  )
}

export default PasswordForm

const Rule = ({
  name,
  regex,
}: {
  isActive?: boolean
  name: string
  regex: string
}) => {
  const { watch } = useFormContext()
  const password = watch("password")

  const rule = new RegExp(`(${regex})`)
  const isConformToRule = rule.test(password)

  return (
    <HStack spacing={3}>
      {password && isConformToRule ? (
        <Icon as={FaCheckCircle} color="green.500" boxSize={5} />
      ) : (
        <Icon as={FaCheckCircle} color="gray.200" boxSize={5} />
      )}
      <Text>{name}</Text>
    </HStack>
  )
}

const Warning = ({ warning }: { warning: string }) => {
  const { t } = useTranslation()
  return (
    <Alert status="warning" borderRadius={4} mt={5}>
      <AlertDescription>
        <Heading as="h3" fontSize={{ base: "sm", md: "md" }} mb={3}>
          {t("components.account.PasswordForm.Warning")}
        </Heading>
        <Text fontSize={{ base: "xs", xl: "sm" }}>{warning}</Text>
      </AlertDescription>
    </Alert>
  )
}

const Suggestions = ({ suggestions }: { suggestions: string[] }) => {
  const { t } = useTranslation()
  return (
    <Alert status="info" borderRadius={4} mt={5}>
      <AlertDescription>
        <Heading as="h3" fontSize={{ base: "sm", md: "md" }} mb={3}>
          {t("components.account.PasswordForm.Suggestions")}
        </Heading>
        <UnorderedList fontSize={{ base: "xs", xl: "sm" }} color="gray.600">
          {suggestions.map((suggestion, index) => (
            <ListItem key={index}>{suggestion}</ListItem>
          ))}
        </UnorderedList>
      </AlertDescription>
    </Alert>
  )
}
